/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


 var tabs = function() {


  jQuery('.tabs-content').on('click','.tab1-more',function(e){
    e.preventDefault();

    // Set target
    var clicked = jQuery(this).data('target');

    // For inactive tabs
    jQuery('.tabpanel').removeClass('tabpanel--visible').attr('aria-hidden','true');
    jQuery('.tab-button--active').removeClass('tab-button--active').attr('aria-expanded','false');

    jQuery('div[data-target="'+clicked+'"]').addClass('tab-button--active').attr('aria-expanded','true');
    jQuery('#'+clicked).addClass('tabpanel--visible').attr('aria-hidden','false');

    document.getElementById(clicked).scrollIntoView();

  });

  jQuery('.section-tabs').on('click','button',function(e){

     e.preventDefault();

     // Set target
     var clicked = jQuery(this).data('target');

     if ( !jQuery(this).hasClass('tabpanel--visible') ) {
       // For inactive tabs
       jQuery('.tabpanel').removeClass('tabpanel--visible').attr('aria-hidden','true');
       jQuery('.tab-button--active').removeClass('tab-button--active').attr('aria-expanded','false');

       // For active tab
       jQuery(this).addClass('tab-button--active').attr('aria-expanded','true');
       jQuery('div[data-target="'+clicked+'"]').addClass('tab-button--active').attr('aria-expanded','true');
       jQuery('#'+clicked).addClass('tabpanel--visible').attr('aria-hidden','false');
     } else {
       return false;
     }

   });

   jQuery('.section-tabs-mobile').on('click','.nested-button',function(e){
      e.preventDefault();

      // Set target
      var clicked = jQuery(this).data('target');

      // For inactive tabs
      jQuery('.tabpanel').removeClass('tabpanel--visible').attr('aria-hidden','true');
      jQuery('.tab-button--active').removeClass('tab-button--active').attr('aria-expanded','false');

      //For active tab
      jQuery('div[data-target="'+clicked+'"]').addClass('tab-button--active').attr('aria-expanded','true');
      jQuery('button[data-target="'+clicked+'"]').addClass('tab-button--active').attr('aria-expanded','true');
      jQuery('#'+clicked).addClass('tabpanel--visible').attr('aria-hidden','false');

   });

 };

 var carousels = function() {

   if ( jQuery('.tab3-blocks').length > 0 ) {


     jQuery('.tab3-blocks').slick({
       responsive: [
         {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              dots:true,
              arrows:false
            }
          }
        ]
     });


     // Resize bug fix to ensure slick reloads on resize/change
     jQuery(window).on('resize orientationchange', function() {
      jQuery('.tab3-blocks').slick('resize');
     });

   }


 };

 var privacybutton = function() {
   if ( jQuery('.privacy-modal').length > 0 ) {
     jQuery('.privacy-modal').find('a').attr({
       'data-toggle': "modal",
       'data-target': "#privModal",
       'role': "button"
     });
   }
 };

 var questionnaire = function() {

    jQuery('.btn-next-1').on('click',function(e){

      $this = jQuery(this);
      $modal = jQuery(this).parents('.modal');

      if( !jQuery(this).parents('.modal-wrap').find('input[type=radio]:checked').val() ){
        e.preventDefault();
        alert('Please select an answer');
      } else {
        $modal.modal('hide');
        jQuery($this.data('target')).modal('show');
      }

    });

    jQuery('.btn-next-2').on('click',function(e){

        $this = jQuery(this);
        $modal = jQuery(this).parents('.modal');

        e.preventDefault();

        var results = [];

        //Check for all radio option, this is passed into the chart
        jQuery('#questionnaire .modal input[type=radio]:checked').each(function () {
            results.push(parseFloat(jQuery(this).val()));
        });

        //Let's make sure all text data is supplied
        var $required = false;
        jQuery(this).parents('form').find(':input[required]:visible').each(function() {
          if (jQuery(this).val() === '') {
            $required = true;
          }
        });

        if( $required ){
          alert('Please complete all fields');
          return false;
        }

        Chart.defaults.global.defaultFontColor = '#fff';
        Chart.defaults.global.defaultFontFamily = '"Raleway", sans-serif';
        Chart.defaults.global.responsive = true;
        Chart.defaults.global.animationEasing = "easeOutBounce";

        Chart.defaults.scale.gridLines.color = "rgba(0,0,0,0)";
        Chart.defaults.scale.gridLines.zeroLineColor = "rgba(0,0,0,0)";

        var industry = jQuery('#industry option:selected').data('averages');

        if( industry !== '' ){

            var industry_avg = industry.split(",");
            var data = {
                labels : ["Smart product capabilities","Product functionality vs cloud","Open or close system","Ecosystem","Data value","Data ownership and access rights","Channel disintermediation","Digital business model","Monetising data","Company scope"],
                datasets: [
                  {
                      label: "Your industry",
                      backgroundColor: "rgba(255,255,255,0)",
                      borderColor: "rgba(29,63,101,0.5)",
                      pointBackgroundColor: "rgba(179,181,198,5)",
                      pointBorderColor: "#fff",
                      pointHoverBackgroundColor: "#fff",
                      pointHoverBorderColor: "rgba(179,181,198,1)",
                      pointRadius: 0,
                      data: industry_avg
                  },
                  {
                      label: "Your company",
                      backgroundColor: "rgba(255,255,255,0.8)",
                      borderColor: "rgba(255,255,255,0)",
                      pointBackgroundColor: "rgba(179,181,198,5)",
                      pointBorderColor: "#fff",
                      pointHoverBackgroundColor: "#fff",
                      pointHoverBorderColor: "rgba(179,181,198,1)",
                      pointRadius: 0,
                      data: results
                  }
                ]
            };

        } else {

          var data = {
              labels : ["Smart product capabilities","Product functionality vs cloud","Open or close system","Ecosystem","Data value","Data ownership and access rights","Channel disintermediation","Digital business model","Monetising data","Company scope"],
              datasets: [
                {
                    label: "Your company",
                    backgroundColor: "rgba(255,255,255,0.8)",
                    borderColor: "rgba(255,255,255,0)",
                    pointBackgroundColor: "rgba(179,181,198,5)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(179,181,198,1)",
                    pointRadius: 0,
                    data: results
                }
              ]
          };

        }

        var ctx = document.getElementById("chart_results").getContext('2d');
        var chart_results = new Chart(ctx, {
          type: 'radar',
          data: data,
          color: 'rgba(255,255,255)',
          fontColor: 'rgba(255,255,255)',
          fontFamily: '"Raleway", sans-serif',
          options: {
              responsive: true,
              maintainAspectRatio: true,
              scale: {
                  reverse: true,
                  ticks: {
                      display: false,
                      backdropColor: 'rgba(255,255,255,0.5)'
                  },
                  pointLabels: {
                      fontSize: 14,
                  },
                  angleLines: {
                      color: 'rgba(255,255,255,1)',
                      lineWidth: 3,
                  },
              }
          }
        });

        $modal.modal('hide');
        jQuery($this.data('target')).modal('show');

        setTimeout(function(){

            var canvas = document.getElementById('chart_results');
            var dataURL = canvas.toDataURL();

            $form = jQuery("#questionnaire");
            $form.find('input[name=dataURL]').val(dataURL);

            $data = $form.serialize();

            jQuery.ajax({
                url: $form.attr('action'),
                type: 'post',
                dataType: 'json',
                data: $data,
                success: function (json) {

                  if( json.status === 'success' ){

                  } else if( json.error ){

                    alert(json.error);

                  } else {

                    alert('Sorry something has gone wrong');

                  }

                }
            });


        }, 1500);

    });

    jQuery('.btn-next-3').on('click',function(e){

      $this = jQuery(this);
      $modal = $this.parents('.modal');
      $modal.modal('hide');

      $(document.body).animate({
          'scrollTop' : $('#contact').offset().top
      }, 2000);

    });

 };

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        jQuery('body').removeClass('no-js');

        tabs();
        carousels();
        privacybutton();
        questionnaire();

        jQuery('#qModal2, #qModal3, #qModal4, #qModal5, #qModal6, #qModal7, #qModal8, #qModal9, #qModal10, #qModal11, #qModal12, #qModal13, #qModal14, #qModal15').on('shown.bs.modal', function () {
          jQuery('body').addClass('modal-open');
        });

        jQuery('.js-describe').on('change', function() {
          if ( jQuery(this).val() === 8 ) {
            jQuery('body').addClass('describe-field-show');
          } else {
            jQuery('body').removeClass('describe-field-show');
          }
        });

        // JavaScript to be fired on all pages
        jQuery('#primaryNavbar').on('hide.bs.collapse', function () {
          jQuery('body').removeClass('menu-visible');
        });
        jQuery('#primaryNavbar').on('show.bs.collapse', function () {
          jQuery('body').addClass('menu-visible');
        });

        var timelineBlocks = $('.cd-timeline-block'),
          offset = 0.8;

        function hideBlocks(blocks, offset) {
          blocks.each(function(){
            ( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.cd-timeline-img, .cd-timeline-content').addClass('is-hidden');
          });
        }

        function showBlocks(blocks, offset) {
          blocks.each(function(){
            ( jQuery(this).offset().top <= $(window).scrollTop()+$(window).height()*offset && jQuery(this).find('.cd-timeline-img').hasClass('is-hidden') ) && jQuery(this).find('.cd-timeline-img, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
          });
        }

        //hide timeline blocks which are outside the viewport
        hideBlocks(timelineBlocks, offset);

        //on scolling, show/animate timeline blocks when enter the viewport
        jQuery(window).on('scroll', function(){
          (!window.requestAnimationFrame)
            ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
            : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
        });

        $('.js_print_fn').on('click',function(e){
          e.preventDefault();
          window.print();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
